.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #333;
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-content {
  display: block;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #333 transparent;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
}
