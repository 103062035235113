.question_record_button {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  background-color: var(--interface-2);
  font-size: 22px;
}

.question_record_button:hover {
  transform: scale(1.05);
  background-color: var(--interface-2);
  opacity: 1;
}

.question_record_button i {
  color: var(--antique-white);
}

.label-flex {
  display: flex !important;
  align-items: end !important;
  justify-content: space-between !important;
  margin-bottom: 10px !important;
}

.label-flex > .left {
  display: flex !important;
  align-items: center !important;
}

.words-count {
  font-size: 14px !important;
}

.icons {
  font-size: 22px;
}

.text-area-warning {
  border: 1px solid red !important;
  background-color: #ffe6e6; /* Optional: light red background */
}

@media (max-width: 768px) {
  .question {
    font-size: 14px;
  }
  .words-count {
    font-size: 12px !important;
  }

  .text-area {
    font-size: 14px !important;
  }
}
