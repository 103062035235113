.custom-modal {
  display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  /* padding: 20px; */
  border: 1px solid #888;
  border-radius: 10px;
  border: 0px;
  position: relative;
  margin: 40px 20px 0px 20px;
  overflow: auto;
}

/* The Close Button */
.custom-modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 10px;
}

.custom-modal-close:hover,
.custom-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
