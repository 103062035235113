.modal-title {
    display: flex;
    align-items: center;
}
  
.modal-logo {
    margin-right: .4rem;
}
  
.modal-container {
    padding: 0rem 4rem;
}
  
.modal-section {
    margin-top: 3rem;
}
  
.modal-uppercase {
    text-transform: uppercase;
}
  
.modal-header {
    max-width: 25rem;
    margin-top: 1rem;
}
  
.modal-divider {
    background-color: lightgray;
    height: 1px;
    margin: 2rem 0rem 1rem 0rem;
}
  
.modal-offer-label {
    display: flex;
    justify-content: space-between;
    align-items: start;
    cursor: pointer;
    position: relative;
}
  
.modal-offer-content {
    display: flex;
    align-items: start;
}
  
.modal-offer-input-container {
    margin-top: .1rem;
}
  
.modal-offer-input {
    margin: 0 .5rem 0 0;
    padding: 10px;
    transform: scale(1.3);
}
  
.modal-offer-details {
    font-size: 1.2rem;
}
  
.modal-offer-subtext {
    color: gray;
    font-size: .8rem;
}
  
.modal-best-value {
    font-weight: bold;
    margin-right: .5rem;
    border: 1px solid black;
    border-radius: 1rem;
    padding: .3rem .5rem;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
}
  
.modal-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
  
.modal-button {
    margin-top: .5rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    width: 50%;
    text-transform: uppercase;
}
  
.modal-payment-icons {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;
}

.secure-payment {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lock-icon {
    margin-right: .4rem;
}

.secure-payment-text {
    margin-bottom: -2px;
}

.coupon-input-container {
    margin-top: 1rem;
    display: flex;
}

.plan-options-list {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    font-weight: 400;
}

.offer-details-container {
    margin-top: -3.5px;
}

@media (max-width: 768px) {
    .modal-container {
        padding: unset;
        /* height: 85svh; */
    }

    .modal-button {
        width: 100%;
    }

    .modal-uppercase {
        text-align: center;
    }

    .modal-header {
        text-align: center;
    }

    .modal-best-value {
        font-size: 10px;
    }

    .modal-offer-label {
        flex-direction: column;
    }

    .modal-offer-content {
        margin-bottom: 1rem;
    }
}