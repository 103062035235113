.email-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    height: 50px;
    width: 350px; /* Make the input more wide */
    border-radius: 5px; /* Minimal curve on borders */
    /* border: #e65c00 2px solid; */
  }
  
  .email-input-container input {
    width: 100%; /* Ensure the input takes up full width */
    padding: 0.5rem; /* Optional: Add padding for better aesthetics */
    border: none; /* Remove the default input border */
    outline: none; /* Remove outline */
    font-size: 1rem; /* Optional: Set font size */
    color: #333; /* Optional: Text color */
  }

  .email-input-container input::placeholder {
    color: #e65c00; /* Orange placeholder text */
  }

  .email-input {
    width: 300px;
    height: 40px;
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 1rem;
  }

  .validate-btn {
    background-color: #ff6d00;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.8rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .validate-btn:hover {
    background-color: #e65c00;
  }

  #myPaymentForm {
    width: 100%;
  }

  .kr-smart-form {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
  }

  .gift-card-form-section {
    display: flex;
    flex-direction: column;
    background-color: #fdeedc;
    font-family: "Arial", sans-serif;
    color: #333;
    padding: 20px;
    padding-top: 70px;
  }