.aai-footer .aai-footer-nav-link {
  font-weight: bold;
}

.aai-social-links a {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.aai-social-links a::after {
  background: none;
}

.aai-social-links a > i {
  color: var(--white);
  font-size: 24px;
}

.aai-footer .aai-footer-copyright p {
  font-size: 14px;
}

.aai-footer .aai-footer-copyright .aai-copyright-text {
  font-size: 14px;
}
