.aai-feature-box_selected {
  background-color: var(--atomic-tangerine);
}

.aai-feature-box_selected .aai-feature-title {
  text-decoration: underline;
}

.aai-feature-box_selected:before {
  padding: 2px;
}
