h1 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

p {
  color: #555;
  margin-bottom: 20px;
}

.result {
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
}

.value {
  font-weight: bold;
  color: #007bff;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button.loading,
.submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.5;
}

.file-input-hidden {
  display: none;
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px dashed #007bff;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  color: #007bff;
  text-align: center;
}

.custom-file-label:hover {
  background-color: #eef3f9;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.copies-input-container {
  text-align: center;
  margin-bottom: 20px;
}

.copies-input-container label {
  font-size: 1.2rem;
  margin-right: 10px;
}

.copies-input-container input {
  width: 60px;
  text-align: center;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-fields {
  width: 100%;
  max-width: 400px; /* Restrict the width for better alignment */
  margin: 0 auto; /* Center the fields horizontally */
}

.input-container {
  display: flex;
  justify-content: space-between; /* Ensures labels and inputs align properly */
  align-items: center;
  margin-bottom: 20px;
}

.input-label {
  width: 40%; /* Adjust this width to control label size */
  text-align: left; /* Align label text to the left */
}

.input-field {
  width: 60%; /* Adjust this width to control input size */
  text-align: left; /* Align text inside the input to the left */
  padding: 5px 10px; /* Add padding for better usability */
  border: 1px solid #ccc; /* Optional: Customize input appearance */
  border-radius: 5px;
}
