.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.gift-card-section {
  display: flex;
  flex-direction: column;
  background-color: #fdeedc;
  font-family: "Arial", sans-serif;
  color: #333;
  padding: 20px;
  padding-top: 70px;
}

.steps-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px; /* Space between number and text */
}

.step-number {
  font-size: 8rem; /* Larger numbers */
  font-weight: bold;
  color: #ff6d00;
}

.step-text {
  font-size: 1.2rem;
  color: #5c5c5c;
  max-width: 300px;
}

.bold-text {
  font-weight: bold;
}

.arrow-icon {
  width: 50px;
  height: auto;
  margin-top: 15px; /* Place arrow below */
}

.asterisk-text {
  font-size: 0.8rem;
  color: #2e2b2b;
  margin-top: 10px;
}