.desktop-burger-menu {
    position: relative;
}

.burger-icon {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    padding: 0;
}

.burger-line {
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.burger-icon.open .burger-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.burger-icon.open .burger-line:nth-child(2) {
    opacity: 0;
}

.burger-icon.open .burger-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

.menu-content {
    margin-top: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    width: 300px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
}

.menu-content.open {
    height: auto;
    opacity: 1;
}

.menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-content ul li {
    margin-bottom: 10px;
    min-width: 18rem;
}

.menu-greeting {
    line-height: 3rem;
    padding-left: 10px;
}

.menu-user-name {
    font-weight: 600;
}

.menu-link {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 16px;
}
