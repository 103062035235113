h2.paid-message-header {
  color: var(--coquelicot);
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

p.paid-message-description {
  color: var(--raisin-black);
  font-weight: bold;
  text-align: center;
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
