.navbar-toggler span,
.navbar-toggler::after,
.navbar-toggler::before {
  background: var(--raisin-black) !important;
}

.cursor-pointer {
  cursor: pointer;
}

section {
  background-color: var(--antique-white);
}

:root {
  --toastify-z-index: 100000 !important;
  --toastify-icon-color-info: var(--coquelicot) !important;
  --toastify-text-color-light: var(--midnight-black) !important;
  --toastify-text-color-dark: var(--midnight-black) !important;
}
