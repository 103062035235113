.selected-img-container {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
}
.selected-img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-delete-button {
  cursor: pointer;
  position: absolute;
  background-color: var(--interface-2);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 1;
}

.dropdown-item {
  cursor: pointer;
}

.upload {
  padding: 0.5rem 0.5rem;
  background-color: #f2c99d;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  width: fit-content;
}

.upload:hover {
  background-color: #faaa8d;
}

.top_question {
  font-size: 24px !important;
}

.first-chapter-free {
  font-size: 14px;
  text-transform: uppercase;
}

.upload-image {
  margin-top: 3rem;
}

@media (max-width: 768px) {
  .upload {
    margin-bottom: unset;
  }

  .top_question {
    font-size: 18px !important;
    max-width: 20rem;
  }

  .upload-image {
    margin-top: unset;
  }

  .btns {
    padding: 0.7rem 1.4rem;
    font-size: 1rem;
  }
}

.loader {
  position: fixed; /* Fixed to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to center */
  z-index: 9999; /* Ensure it's above other content */
  font-size: 3rem; /* Make the loader bigger */
  color: #007bff; /* Customize the color of the loader */
}

.loader i {
  animation: spin 2s linear infinite; /* Infinite spinning */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure it's above other elements */
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-actions button {
  margin: 0.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
